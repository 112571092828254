@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,600;1,700&display=swap');

:root {
  --bs-primary: #e7318b;
  --bs-primary-rgb: 231, 49, 139;
  --color-accent: #e37e92;
  --bs-gray-dark: #939598;
  --bs-gray: #bcbec0;
  --bs-secondary: #7f3f98;
  --bs-secondary-rgb: 127, 63, 152;

  --bs-navbar-color: white;


}

.btn-outline-primary {

  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}


body {
  font-family: 'Open Sans', Calibri, 'Trebuchet MS', sans-serif;
  background-color: var(--bs-primary);
  background: url('./img/bg.png');
  background-size: cover;
  background-position: center;
}

h2 {
  color: var(--bs-gray-dark);
  text-transform: none;
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

h3 {
  text-transform: none;
  font-weight: bold;
  text-align: center;
  font-size: 1.3em;
  color: var(--bs-primary);
}

button.btn-primary {
  border: 2px solid var(--bs-primary);
  font-weight: bold;
  transition: all 200ms ease;
  background-color: var(--bs-primary);
  border-radius: 30px;
  padding: 5px 40px;
}

button.btn-primary:hover,
button.btn-primary:focus-visible {
  border: 2px solid var(--bs-secondary);
  color: white;
  background-color: var(--bs-secondary);
  transform: scale(1.02);
}

button.btn-primary:active {
  border: 3px solid var(--bs-secondary) !important;
  color: white;
  background-color: var(--bs-secondary) !important;
  transform: scale(1.01);
}

button.btn-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  padding: 0px;
}

@media (max-width: 767px) {
  button.btn-icon {
    width: 20px;
    height: 20px;
    font-size: 14px;
    margin-top: -2px;
  }
}

input[type="text"],
select,
.form-select {
  border: 2px solid var(--bs-primary);
  border-radius: 30px;
  outline: none;
  padding: 10px 20px;
  color: #222222;
  background-color: white;
  font-weight: bold;
  ;
}

input[type="text"]:focus,
select:focus,
.form-select:focus {
  border-color: var(--bs-secondary);
}

::placeholder {
  color: var(--bs-gray);
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--bs-gray);
}

.onhover {
  /* display: none !important; */
  animation: fadeIn 2000ms ease;
}

.onhover-container:not(:hover) .onhover {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.App {
  min-height: 100vh;
}

main {
  z-index: 0;
  padding: 0px 10px;
}

main .layout.container {
  background-color: #ffffff;
  padding: 20px 10px;
  padding-bottom: 60px;
  border-radius: 20px;
  box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
}

@media (max-width: 575px) {
  main .layout.container {
    border-radius: 10px;

  }
}

main:has(.login) {
  padding: 0px;
  ;
}

main .layout.container:has(.login) {
  padding: 10px 0px 60px 0px;
  background-color: transparent;
  box-shadow: none;
}

header {
  min-height: 60px;
  z-index: 2;
  position: relative;
}

header a.nav-link {
  font-size: 13px;
  display: flex;
  margin: 0px 10px;
}

header a.nav-link {
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  text-align: center;
  line-height: 1em;
  ;
}

header a.nav-link:focus,
header a.nav-link:active {
  color: white
}

header a.nav-link:hover {
  font-weight: bold;
  color: white;
  transform: scale(1.05)
}

header .nav-link img {
  width: auto;
  height: 30px;
  display: block;
}

header .navbar-toggler,
header .navbar-toggler:focus {
  color: white;
  border: none;
  box-shadow: none;
}

header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

header .navbar-nav {
  flex-direction: row;
  justify-content: space-evenly;
}

@media (max-width: 991px) {
  .navbar-nav {
    margin: 20px 0px;
  }
}

footer {
  height: 60px;
  font-size: 12px;
  background-color: #fff6f6;

  width: 100vw;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  z-index: 2;
  position: relative;
}

footer a,
footer .btn-link {
  color: white;
  text-decoration: none;
  font-size: 12px;
  padding: 0px;
}

.layout {
  height: calc(100vh - 120px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ;
}

@media (max-width: 767px) {
  .ddi-group {
    margin-left: -10px;
    margin-right: -10px;
    flex-grow: 1;
  }
}

.ddi-group h3 {
  margin: 20px;
  margin-top: 30px;
  /* background-color: #F6F6F6; */
  display: block;
}

@media (min-width: 1024px) {
  .ddi-tbl {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .ddi-tbl {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.ddi-tbl thead {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: var(--bs-gray-dark);
  background-image: url('./img/bg_bar_gray.png');
  background-size: cover;
  /* line-height: 30px; */
  text-shadow: 0px 0px 10px #000000;
}

.ddi-tbl thead th {
  padding-top: 5px;
  padding-bottom: 5px;
}


.ddi-tbl thead th:first-child {
  border-radius: 5px 0 0 5px;
}

.ddi-tbl thead th:last-child {
  border-radius: 0 5px 5px 0;
}

.ddi-tbl td {
  border-bottom: 1px solid var(--bs-gray);
  padding: 0px 10px;
  font-size: 30px;
  width: 100px;
}


.ddi-tbl tr td:first-child {
  font-weight: 600;
  min-width: 300px;
  font-size: 1em;
}

.ddi-tbl tr td:not(:first-child),
.ddi-tbl tr th:not(:first-child) {
  min-width: 100px;
  text-align: center;
}

@media (max-width: 767px) {

  .ddi-tbl {
    width: 100%;
  }

  .ddi-tbl td {
    font-size: 20px;
    padding: 5px 5px;
  }

  .ddi-tbl thead {
    font-size: 14px;
  }

  .ddi-tbl tr td:first-child {
    width: 40%;
    min-width: initial;
    font-size: 14px;
  }

  .ddi-tbl tr td:not(:first-child),
  .ddi-tbl tr th:not(:first-child) {
    width: 20%;
    min-width: 0;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .ddi-tbl td {
    font-size: 14px;
    padding: 5px 3px;
  }

  .ddi-tbl thead {
    font-size: 12px;
  }

  .ddi-tbl thead th>span {
    display: block;
    margin-top: -3px;
    text-shadow: 0px 0px 5px #333;
  }

  .ddi-tbl tr td:first-child {
    font-size: 14px;
    max-width: 40%;
  }
}

.ddi-list.sticky-top {
  top: -20px;
}


.ddi-score-0 {
  color: #7AB575;
}

.ddi-score-1 {
  color: #f3d021;
}

.ddi-score-2 {
  color: #E07E31;
}

.ddi-score-3 {
  color: #C12C52;
}


.card-pink,
.card-pink-selected>.card-header {
  background-color: var(--bs-primary);
  background: url("./img/bg_card_pink_light.png");
  background-size: cover;
  color: #7d1a6e;
  /* color: var(--bs-primary); */
  /* color: var(--bs-secondary); */
  font-size: 1.02em;
  font-weight: 700;
  border-radius: 10px;
  /* text-transform: uppercase; */
  text-align: center;
  padding: 10px;
  /* box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1); */
  /* border: 2px solid var(--bs-primary); */
  border: 2px solid #e7318b4d;
  /* text-shadow: 0px 0px 4px rgba(68, 6, 62, 0.2); */
  cursor: pointer;
  transition: all 300ms ease;
}

.card-pink {
  height: 95px;
}

.card-pink:hover {
  transform: scale(1.03);
  color: #440e3b
}

a:has(.card-pink) {
  color: white;
  text-decoration: none;
}

.card-pink-selected>.card-header {
  width: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-pink-selected>.card-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid var(--bs-primary);
  border-top: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-pink.card-pink.selected:hover {
  transform: scale(1);
}

@media (max-width: 575px) {
  .card-pink-selected {
    margin-left: -18px;
    margin-right: -18px;
  }

  .card-pink-selected>.card-detail {
    padding: 0px 10px;
  }
}

.home .card-pink {
  background: url("./img/bg_card_pink.png");
  color: white;
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  height: 200px;
  width: 250px;
}


.home .card-pink img {
  height: 80px;
  width: auto;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .home .card-pink {
    width: 100%;
    height: 120px;
    justify-content: left !important;
    text-align: left;
    font-size: 1.3em;
  }

  .home .card-pink img {
    height: 80px;
    margin-bottom: 0px;
    margin-right: 20px;
  }
}

.home .nav-link img {
  height: 40px;
  width: auto;
}

.input-group-append {
  position: absolute;
  right: 15px;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0px;
  color: var(--bs-primary)
}

.input-wrapper:has(input:focus) .input-group-append {
  color: var(--bs-secondary);
}

.patient-view {
  border: 1px solid #ccc;
  position: fixed;
  bottom: 0px;
  right: 3%;
  background-color: white;
  background: url("./img/bg_card_pink_light.png");
  background-size: cover;
  margin-left: -20px;
  padding: 5px;
  padding-top: 10px;
  background-color: #f6f2f4;
  z-index: 1030;
  /* sticky-top is 1020 */
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
}

.patient-view table.mb-5 {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}

.patient-view h3 {
  margin-bottom: 0px;
}

.patient-view .ddi-list-container {
  max-height: 40vh;
  overflow-y: scroll;
}

.patient-view .ddi-list.sticky-top {
  top: 0px;
}

@media (max-width: 575px) {
  .patient-view {
    width: 100%;
    right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}


.patient-view .scroll-shadows {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.patient-view .scroll-shadows-parent:after {
  content: '';
  background:
    /* Shadow BOTTOM */
    radial-gradient(farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)) center bottom;
  height: 10px;
  display: block;
  position: absolute;
  left: 0px;
  margin-top: -10px;
  width: 100%;
}

.patient-view .scroll-shadows:after {
  background-color: white;
  background: url("./img/bg_card_pink_light.png");
  content: '';
  display: block;
  position: sticky;
  height: 10px;
  z-index: 1;
}

.patient-profile-card {
  background-color: rgb(254 242 251);
  background-image: url("./img/icons/Icone_Perfil_Doente.png");
  background-repeat: no-repeat;
  background-position: -20px calc(100% + 50px);
}

.tabs-container {
  border: 1px solid var(--bs-secondary-bg);
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
}

.tabs-container li {
  margin-bottom: 0.85em;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-secondary);
}

.nav-tabs .nav-link.active {
  font-weight: bold;
}

.app-install img {
  transition: all 600ms ease
}

.app-install:hover img {
  transform: scale(1.2);
}